<template>
    <div style="height:55px;" >
        <nav class="navbar navbar-expand-lg border-bottom d-middle">
            <div class="col-3 bg-danger" />
            <div class="col d-flex j-center">
                <router-link :to="{ name: 'home', }">
                    <img class="obj-cover p-2" height="58" src="/img/logos/logoOrbitaOscuro.png" style="max-width:100%;" />
                </router-link>
            </div>
            <div class="col-3 d-middle justify-content-end" v-if="user">
                <el-tooltip placement="bottom" content="Proyectos" effect="light">
                    <router-link :to="{name: 'home'}">
                        <div :class="`navbar-tab ${ $route.name == 'home' ? 'active' : ''} d-middle-center mx-1`">
                            <i class="icon-inmobiliaria f-15" />
                        </div>
                    </router-link>
                </el-tooltip>
                <el-tooltip placement="bottom" content="Servicios" effect="light">
                    <router-link :to="{name: 'tiendas.main'}">
                        <div :class="`navbar-tab ${ $route.name == 'tiendas.main' ? 'active' : ''} d-middle-center mx-1`">
                            <i class="icon-tienda f-15" />
                        </div>
                    </router-link>
                </el-tooltip>
                <el-tooltip placement="bottom" content="Ventanas emergente" effect="light">
                    <router-link :to="{name: 'ventanas'}">
                        <div class="navbar-tab d-middle-center mx-1">
                            <i class="icon-anuncios f-18" />
                        </div>
                    </router-link>
                </el-tooltip>
                <el-tooltip placement="bottom" content="Estadisticas" effect="light">
                    <router-link :to="{name: 'estadisticas'}">
                        <div :class="`navbar-tab ${ $route.name == 'estadisticas' ? 'active' : ''} d-middle-center mx-1`">
                            <i class="icon-estadisticas f-15" />
                        </div>
                    </router-link>
                </el-tooltip>
                <el-tooltip placement="bottom" content="Configuracíon" effect="light">
                    <router-link :to="{name: 'configurar.manager'}">
                        <div class="navbar-tab d-middle-center mx-1">
                            <i class="icon-configuracion f-18" />
                        </div>
                    </router-link>
                </el-tooltip>
                <el-popover
                    placement="bottom"
                    width="200"
                    trigger="click"
                >
                    <p class="br-5 px-2 cr-pointer"  @click="logout">
                        Cerrar Sesión
                    </p>
                    <div slot="reference" class="log-out-button p-1 cr-pointer">
                        <div class="text-general rounded-circle bg-general text-white f-12 d-middle-center h-100 w-100">
                            M
                        </div>
                    </div>
                </el-popover>        
          </div>
        </nav>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
	computed: {
		...mapGetters({
				user:'auth/user',
		}),
	},
	methods: {
		logout(){
			this.$store.dispatch('auth/logout')
			this.$router.push({name:'login'})
		},
	},
}
</script>

<style lang="scss" scoped>
a{
    text-decoration: none !important;
}
.navbar{
    position: fixed;
    width:100vw;
    top: 0px;
    height: 55px;
    z-index: 5;
    background: var(--gr-r-general);
    /* box-shadow: 0 3px 6px 0 #00000029; */
    /* border-radius: 0 0 8px 8px; */
}
.hover-i li:hover{
    background: #471F9B;
    border-radius: 12px;
}
.navbar-tab{
    color: #000000;
    width: 38px;
    height: 38px;
    &:hover{
        border-radius: 50%;
        color: #ffffff;
        background-color: var(--color-general);
    }
    &.active{
        border-radius: 50%;
        color: #ffffff;
        background-color: var(--color-general);
    }
}

.log-out-button{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    &:hover{
        background-color: #00d9b556;
        transition: 0.5ms;
    }
}

.icon-inmobiliaria{
    &::before{
        display: contents !important;
    }
}
</style>
